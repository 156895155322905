import React from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
	display:flex;
	position: relative;
	height:100%;
	flex:1 0 0%;
	justify-content:center;
	align-items:center;
`;

const ImgStyles = styled.img`
	position: absolute;
	display: block;
	width:310px;
	z-index:${props => props.zIndex};
	transform-origin: center center;
	transition: all 0.2s;
	opacity:0;
	&.selected{
		opacity:1;
	}
	@media (max-width: 420px) {
		width:220px;
	}
`;
const TabContentImage = ({ cubeData, selectedCube, contentType }) => {
	return (
		<ImageWrapper>
			{
				cubeData.map(cd => (
					<ImgStyles 
						src={cd.image}
						alt={cd.title}
						offset={contentType === 'trade' ? '20%' : '18%'}
						zIndex={cd.number}
						className={cd.number === selectedCube ? 'selected' : ''}
					/>
				))
			}
		</ImageWrapper>
	);
};

export default TabContentImage;