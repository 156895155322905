import React, { useState } from 'react';
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { 
	TabContentStyles, 
	TabCentralContent,
	TabContentBottomBorder, 
	BenefitsContainer, 
	BenefitsStyles ,
	BenefitsDetailedContainer
} from '../styles/TabContent'
import { CentralStyle, VerticalSpace, CentralCnt } from '../styles/Layout';

import handA from '../images/tt-ss-1-a.png';
import handB from '../images/tt-ss-1-b.png';
import handC from '../images/tt-ss-1-c.png';
import handD from '../images/tt-ss-1-d.png';
import Cube from './Cube';
import TabContentSubTitle from './TabContentSubTitle';
import TabContentImage from './TabContentImage';
const cubeData = [
	{
		colour:'pink',
		border:'pink',
		number:1,
		title:"Post your job to tell the tribe what you need",
		image:handA,
		subTitle:"It's FREE<br />to join!"
	},
	{
		colour:'red',
		border:'red',
		number:2,
		title:"One click connects you to unlimited available trades people",
		image:handB,
		subTitle:"It's FREE<br />to join!"
	},
	{
		colour:'blue',
		border:'blue',
		number:3,
		title:"Only available trades people will reply…good eh?",
		image:handC,
		subTitle:"It's FREE<br />to join!"
	},
	{
		colour:'green',
		border:'green',
		number:4,
		title:"You choose, job done",
		image:handD,
		subTitle:"It's FREE<br />to join!"
	}
]

const TabContentCustomer = () => {

	const [selectedCube, setSelectedCube] = useState(1);

	function onCubeSelected(selectedCubeNumber){
		setSelectedCube(selectedCubeNumber)
	}

	return (
		<>
			<CentralStyle>
				<h2 className="center yellow">Connecting you live to your local 
	available Tradesperson</h2>

				<CentralCnt>
					<p>Phonecall after phonecall and still no available tradesperson to do your job?</p>
					<p>We hear you! The challenge isn't finding tradespeople, its finding "available" tradespeople.</p>
					<p></p>One click, only available tradespeople reply, the choice is yours.
					<p>As easy as ordering your taxi or takeway. It does what it says on the tin!</p>
				</CentralCnt>
			

				{/* <p>You’ve a list of 20 trades, how do you know if they are available? </p>
				<p>We hear you! The challenge isn’t finding tradespeople, its finding ‘available’ tradespeople. </p>
				<p>No more phone calls and emails. There is now a smarter way.</p>
				<p>Connecting you live to your available local tradesperson. As easy as your taxi or takeaway. Its not rocket science.</p> */}

				<VerticalSpace />
				<h2 className="center yellow">Why join the tribe?</h2>
				<VerticalSpace />

				<BenefitsDetailedContainer>

				<BenefitsStyles colour="yellow">
					<span><FaCheck></FaCheck></span>
					Free, fast way to post your jobs
				</BenefitsStyles>

				<BenefitsStyles colour="yellow">
					<span><FaCheck></FaCheck></span>
					No time wasting phonecalls or emails
				</BenefitsStyles>
				</BenefitsDetailedContainer>

				<BenefitsDetailedContainer>
					<BenefitsStyles colour="yellow">
						<span><FaCheck></FaCheck></span>
						Local tradespeople instantly notified of your job
					</BenefitsStyles>

					<BenefitsStyles colour="yellow">
						<span><FaCheck></FaCheck></span>
						Only available local tradespeople reply
					</BenefitsStyles>
				</BenefitsDetailedContainer>

				<BenefitsDetailedContainer>
					<BenefitsStyles colour="yellow">
						<span><FaCheck></FaCheck></span>
						Trusted and vetted
					</BenefitsStyles>

					<BenefitsStyles colour="yellow">
						<span><FaCheck></FaCheck></span>
						Job Done
					</BenefitsStyles>
				</BenefitsDetailedContainer>


				<VerticalSpace />
				<h4 className="center green">Instantly connect with trades people ready to start your job now</h4>

				<BenefitsContainer>

					<BenefitsStyles colour="yellow">
						<span><FaCheck></FaCheck></span>
						Time Savvy
					</BenefitsStyles>

					<BenefitsStyles colour="red">
						<span><FaCheck></FaCheck></span>
						Live availability, no disappointing phone calls
					</BenefitsStyles>

					<BenefitsStyles colour="green">
						<span><FaCheck></FaCheck></span>
						Local
					</BenefitsStyles>

				</BenefitsContainer>
			</CentralStyle>
			<VerticalSpace />

			<TabContentStyles colour="yellow">
				<TabCentralContent>
					<div className="cube-wrapper">
						<Cube 
							cubeData={cubeData}
							onCubeSelected={onCubeSelected}
							selectedCube={selectedCube}
							hoverColour="yellow"
						/>
					</div>
					<div className="details-wrapper">
						<div className="detail img-wrapper">
							<TabContentImage 
								cubeData={cubeData}
								selectedCube={selectedCube}
								contentType="customer"
							/>
						</div>
						
						<div className="detail sub-wrapper">
							<TabContentSubTitle
								cubeData={cubeData}
								selectedCube={selectedCube}
							/>
						</div>
					</div>
					
				</TabCentralContent>
				
				{/* <TabContentBottomBorder /> */}
			</TabContentStyles> 

			
		</>
	);
};

export default TabContentCustomer;