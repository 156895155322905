
import styled from 'styled-components';


export const TabContentStyles = styled.div`
	margin-top:2rem;
	background-color:var(--${props => props.colour});
	min-height:330px;
	width:100%;
	display:flex;
	flex-direction:row;
	@media (max-width: 900px) {
		/* flex-direction:column; */
		min-height:660px;
	}
	/* @media (max-width: 420px) {
		min-height:550px;
	} */
`;
export const TabCentralContent = styled.div`
	max-width:1200px;
	width:90%;
	display:flex;
	flex-direction:row;
	margin:2rem auto;
	.cube-wrapper{
		width:40%;
	}
	.details-wrapper{
		display:flex;
		flex-direction:row;
		flex:1 0 0%;
	}
	.details-wrapper .detail{
		flex:1 0 0%;
		display:flex;
		justify-content:center;
		align-items:center;
	}
	@media (max-width: 900px) {
		flex-direction:column;
		.cube-wrapper{
			width:100%;
		}
	}
	@media (max-width: 420px) {
		flex-direction:column;
		.details-wrapper{
			flex-direction:column;
		}
		.details-wrapper .detail.img-wrapper{
			flex:1 0 0%;
		}
		.details-wrapper .detail.sub-wrapper{
			flex:0 1 20%;
		}
	}

`;
export const TabContentBottomBorder = styled.div`
	background-color:var(--black);
	height:3px;
	width:100%;
	display:block;
	position: absolute;
	bottom: -1px;
	z-index:10;
`;
export const BenefitsContainer = styled.div`
	display:flex;
	justify-content:center;
	@media (max-width: 900px) {
		flex-direction:column;
	}
`;


export const BenefitsDetailedContainer = styled.div`
	display:flex;
	flex-direction:row;
	margin-bottom:1.5rem;
	div{
		flex:1 0 0%;
	}
	@media (max-width: 900px) {
		flex-direction:column;
		margin-bottom:0;
	}
	/* --size:100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 20px;
	width:var(--size);
	height:var(--size); */
	/* @media (max-width: 900px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
	} */
`;
export const BenefitsStyles = styled.div`
	margin:0 1rem;
	display:flex;
	/* justify-content:center; */
	font-size:1.5rem;
	span{
		color:var(--${props => props.colour});
		margin-right:0.75rem;
	}
	@media (max-width: 900px) {
		margin:0.5rem auto;
	}
`;