import React from 'react';
import styled from 'styled-components';

const SubTitle = styled.div`
	display:flex;
	position: relative;
	width:100%;
	height:100%;
	justify-content:flex-start;
	align-items:center;
	@media (max-width: 900px) {
		justify-content:center;
		
  } 
	@media (max-width: 420px) {
		align-items:flex-start;
		min-height:100px;
	}
`;
const Title = styled.div`
	color:var(--black);
	display:flex;
	position: absolute;
	z-index:${props => props.number};
	text-align:center;
	font-weight:700;
	font-size:3rem;
	transition: all 0.2s;
	opacity:0;
	&.selected{
		opacity:1;
	}
	@media (max-width: 900px) {
		font-size:2.5rem;
	}
	@media (max-width: 420px) {
		font-size:2rem;
		padding:0.5rem;
	}
`;

const TabContentSubTitle = ({ cubeData, selectedCube }) => {
	return (
		<SubTitle>
			{
				cubeData.map(cd => (
					<Title 
						dangerouslySetInnerHTML={{ __html: cd.subTitle }}
						className={cd.number === selectedCube ? 'selected' : ''}
					/>
				))
			}
		</SubTitle>
	);
};

export default TabContentSubTitle;