import React, { useState } from 'react';
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { 
	TabContentStyles, 
	TabCentralContent,
	TabContentBottomBorder, 
	BenefitsContainer, 
	BenefitsDetailedContainer,
	BenefitsStyles 
} from '../styles/TabContent'
import { CentralCnt, CentralStyle, VerticalSpace } from '../styles/Layout';

import handA from '../images/tt-ss-2-a.png';
import handB from '../images/tt-ss-2-b.png';
import handC from '../images/tt-ss-2-c.png';
import handD from '../images/tt-ss-2-d.png';
import Cube from './Cube';
import TabContentSubTitle from './TabContentSubTitle';
import TabContentImage from './TabContentImage';
const cubeData = [
	{
		colour:'orange',
		border:'orange',
		number:1,
		title:"Join the tribe, only 69p a day,",
		image:handA,
		subTitle:"Unlimited local job leads 24/7 direct to your phone. Even while on the job"
	},
	{
		colour:'pink',
		border:'pink',
		number:2,
		title:"Create your profile",
		image:handB,
		subTitle:"Unlimited local job leads 24/7 direct to your phone. Even while on the job"
	},
	{
		colour:'yellow',
		border:'yellow',
		number:3,
		title:"Get instant notifications of jobs",
		image:handC,
		subTitle:"Unlimited local job leads 24/7 direct to your phone. Even while on the job"
	},
	{
		colour:'green',
		border:'green',
		number:4,
		title:"Respond if you're available to do the job, Connect to the customer",
		image:handD,
		subTitle:"Unlimited local job leads 24/7 direct to your phone. Even while on the job"
	}
]



const TabContentTradesperson = () => {

	const [selectedCube, setSelectedCube] = useState(1);

	function onCubeSelected(selectedCubeNumber){
		setSelectedCube(selectedCubeNumber)
	}

	return (
		<>
			<CentralStyle>
				<h2 className="center blue">Tired of paying high annual fees, paying for every job lead, wasting your money and time only to be at the back of the queue for jobs in dinosaur directories?</h2>
				<CentralCnt>
					<p>Me too. We are banging the drum for all to be at the front of the queue on a level playing field, enabling you to be seen, secure new customers with instant connectivity.</p>
				</CentralCnt>
				
				<VerticalSpace />
				<h2 className="center yellow">Why join the tribe?</h2>
				<VerticalSpace />
				<BenefitsDetailedContainer>

					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Unlimited local job leads 24/7 direct to your phone. Even while on the job
					</BenefitsStyles>

					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						1 press of a button, connects you live and direct to your customers via the app or website
					</BenefitsStyles>
				</BenefitsDetailedContainer>

				<BenefitsDetailedContainer>
					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Always at the front of the queue for new jobs
					</BenefitsStyles>

					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						If a job cancels, Trade Tribe fills the gap
					</BenefitsStyles>
				</BenefitsDetailedContainer>

				<BenefitsDetailedContainer>
					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Time savvy, no timewasters, honest and transparent
					</BenefitsStyles>

					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Your own dedicated business profile, showcase your work, your way
					</BenefitsStyles>
				</BenefitsDetailedContainer>

				<BenefitsDetailedContainer>
					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Unique member offers
					</BenefitsStyles>

				</BenefitsDetailedContainer>

				<VerticalSpace />
				<h2 className="center blue">Don't take our word for it, have 3 months on us! Join the tribe</h2>
				<p className="center"></p>
				<BenefitsContainer>

					<BenefitsStyles colour="blue">
						<span><FaCheck></FaCheck></span>
						Level playing field for jobs
					</BenefitsStyles>

					<BenefitsStyles colour="red">
						<span><FaCheck></FaCheck></span>
						No hidden costs
					</BenefitsStyles>

					<BenefitsStyles colour="green">
						<span><FaCheck></FaCheck></span>
						Be seen and be heard
					</BenefitsStyles>

				</BenefitsContainer>
			</CentralStyle>
			
			<VerticalSpace />

			<TabContentStyles colour="blue">
				<TabCentralContent>
					<div className="cube-wrapper">
						<Cube 
							cubeData={cubeData}
							onCubeSelected={onCubeSelected}
							selectedCube={selectedCube}
							hoverColour="blue"
						/>
					</div>
					<div className="details-wrapper">
						<div className="detail img-wrapper">
							<TabContentImage 
								cubeData={cubeData}
								selectedCube={selectedCube}
								contentType="trade"
							/>
						</div>
						
						<div className="detail sub-wrapper">
							<TabContentSubTitle
								cubeData={cubeData}
								selectedCube={selectedCube}
							/>
						</div>
					</div>
					
				</TabCentralContent>
				
				{/* <TabContentBottomBorder /> */}
			</TabContentStyles> 

			
		</>
	);
};

export default TabContentTradesperson;