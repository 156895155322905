import React, { useState } from 'react';
import styled from 'styled-components';
import TabContentCustomer from './TabContentCustomer';
import TabContentTradesperson from './TabContentTradesperson';
import hand from '../images/icon.png';
import { CentralStyle, VerticalSpace, CentralCnt } from '../styles/Layout';
import AppSignUp from './AppSignUp';
import ContactFormPopUp from './ContactFormPopUp';


const WraperStyle = styled.div`
	margin-top:5rem;
	display:flex;
	flex-direction:column;
	justify-content:center;
`;
const TabsStyle = styled.div`
	margin:1rem 0;
`;
const TabNavStyle = styled.nav`
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:center;
`;
const TabButtonStyle = styled.button`
	position:relative;
	display:flex;
	align-items:center;
	background-color:var(--black);
	border:solid 1px var(--white);
	max-width:290px;
	min-height: 60px;
	margin:0 1rem;
	&.selected{
		background-color:var(--white);
		color:var(--black);
	}
	img{
		position:absolute;
		z-index:5;
		top:0px;
		display:block;
		margin:0;
		height:58px;
		--rotate: -10deg;
    transform: rotate(var(--rotate));
	}
	&.selected.left > img{
		left:-24px;
	}
	&.selected.right > img{
		--rotate: 10deg;
		right:-24px;
	}
	@media (max-width: 900px) {
		font-size:1.4rem;
    max-width:120px; 
		min-height: 45px;
		margin:0 0.5rem;
		img{
			height:40px;
			top:4px;
  	}
		&.selected.left > img{
			left:-16px;
		}
		&.selected.right > img{
			right:-16px;
		}
	}
`;


const TabsContent = () => {

	const [selectedTab, setSelectedTab] = useState(null);
	
	
	return (
		<WraperStyle>
			<CentralStyle>
				<h2 className="center">A helping hand, connecting the community</h2>
				<CentralCnt>
					<p>
					35 years in the industry, and always hear the same pain - "I can’t find a local 'available' tradesperson." This has to change. I have created the smarter way, in just one click.
					</p>
					{/* <p>I have 35 years in the industry, I always hear the same pain - I can’t find a local “available” tradesperson. This has to change. I have created the smarter way, fair for all in just one click</p> */}
					<p className="blue"><strong>Its not rocket science!</strong></p>
				</CentralCnt>
			</CentralStyle>
			

			<TabsStyle>
				<TabNavStyle aria-label="Tabs">
					<TabButtonStyle 
						onClick={() => setSelectedTab('customer')}
						className={selectedTab === "customer" ? 'selected left' : ''}
					>
						I need a Tradesperson
						{ selectedTab === "customer"  && <img src={hand} alt="TradeTribe" />}
					</TabButtonStyle>
					<TabButtonStyle 
						onClick={() => setSelectedTab('trade')} 
						className={selectedTab === "trade" ? 'selected right' : ''}
					>
						Tradespersons entrance
						{ selectedTab === "trade"  && <img src={hand} alt="TradeTribe" />}
					</TabButtonStyle>
				</TabNavStyle>
			</TabsStyle>

			{ selectedTab === "customer" && (
				<TabContentCustomer />
			)}
			{ selectedTab === "trade" && (
				<TabContentTradesperson />
			)}

			<VerticalSpace />
			<CentralStyle>

		
				{ selectedTab === "customer" && (
					<h2 className="center yellow">Trade Tribe is coming, move with the times.<br />Sign up now and join the tribe.</h2>
				)}
				{ selectedTab === "trade" && (
					<>
						<h2 className="center yellow">Trade Tribe is coming, move with the times.<br />Sign up now and join the tribe.</h2>
						<h5 className="center green">Get your first 3 months FREE!&#42;</h5>
					</>
				)}
				
			</CentralStyle>
			
			{ selectedTab && (
				// <AppSignUp />
				<ContactFormPopUp />
			)}

			
		</WraperStyle>
	);
};

export default TabsContent;