import React from 'react';
import styled from "styled-components";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";


const PlayerCloseBar = styled.div`
  background-color:var(--yellow);
	display:flex;
	height:30px;
	width:100%;
	align-items:center;
	justify-content:flex-end;
`
const CloseBtn = styled.button`
  display:flex;
	justify-content:center;
	align-items:center;
	width:30px;
	height:30px;
	border:none;
	background-color:transparent;
	color:var(--black);
	font-size:3rem;
	line-height:30px;
	padding:0;
	margin-right:5rem;
	&:hover{
		color:var(--red);
	}
`

const PopUpCloseBar = ({ callback }) => {
	return (
		<PlayerCloseBar>
			<CloseBtn onClick={() => callback()}>
				<IoMdCloseCircle />
			</CloseBtn>
		</PlayerCloseBar>
	);
};

export default PopUpCloseBar;