import React, { useState } from "react"
import styled from "styled-components";
import bg from '../images/tradetribe-tradesperson.jpg';
import TabsContent from "../components/TabsContent";
import TopBanner from "../components/TopBanner";
import { IoMdPlayCircle } from "@react-icons/all-files/io/IoMdPlayCircle";
import Player from "../components/Player";
import { Helmet } from "react-helmet"

const BannerStyles = styled.div`
	min-height:520px;
	width:100%;
	background-color:var(--red);
	display:flex;
	justify-content:center;
	align-items:flex-end;
	background-image:url(${bg});
	background-size: cover;
	background-repeat: no-repeat;
  background-position: center;
	.banner-text{
		background-color:var(--white);
		width:75%;
		padding:2rem;
	}
	@media (max-width: 900px) {
		h1{
			font-size:2rem;
		}
		h2{
			font-size:1.9rem;
		}
  }
`;
const UnderBannerStyles = styled.div`
	min-height:100px;
	width:100%;
	display:flex;
	justify-content:center;
	align-items:flex-start;
`;
const Banner = styled.div`
	background-color:var(--${props => props.colour});
	width:75%;
	min-height:80px;
	padding:0 2rem;
	position:relative;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	h1{
		font-size:3rem;
	}
	h1 span{
		font-size:2rem;

		/* font-size: 1.5em; */
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
	}
	@media (max-width: 900px) {
		padding-bottom:1rem;
		h1{
			font-size:1.9rem;
		}
		h1 span{
			font-size:1.8rem;
		}
  }
`;
const FloatButton = styled.div`
	position: absolute;
	display:flex;
	width:100%;
	top:320px;
	justify-content:center;
	align-items:center;
	z-index:5;
	@media (max-width: 900px) {
		top:360px;
  }
`;
const PlayBtn = styled.button`
  display:flex;
	justify-content:center;
	align-items:center;
	width:80px;
	height:80px;
	border:none;
	background-color:transparent;
	color:var(--white);
	font-size:3rem;
	line-height:30px;
	padding:0;
	margin-right:5rem;
	.play-icon{
		font-size:8rem;
		opacity:.8;
		cursor: pointer;
	}
	&:hover{
		color:var(--red);
		.play-icon{
			opacity:.8;
		}
	}
`

const Text = styled.h2`
	font-size:2rem;
	font-weight:400;
	letter-spacing: 0.5px;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	@media (max-width: 900px) {
		font-size:1.6rem;
		letter-spacing: 0.5px;
  }
`;



// markup
const IndexPage = () => {
	const [showVideo, setShowVideo] = useState(false);

	function onCloseCallback(){
		console.log("HERE")
		setShowVideo(false);
	}
  return (
		<>
			<div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Trade Tribe</title>

					<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />

					<meta name="description" content="Trade Tribe. the free, fast, smart new way to connect you to your local, vetted, available Tradesperson." />
					<meta name="robots" content="index, follow" />
					<link rel="canonical" href="https://www.tradetribe.co.uk/" />
					<meta property="og:locale" content="en_GB" />
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Trade Tribe" />
					<meta property="og:description" content="Trade Tribe. the free, fast, smart new way to connect you to your local, vetted, available Tradesperson." />
					<meta property="og:url" content="https://www.tradetribe.co.uk/" />
					<meta property="og:site_name" content="Trade Tribe" />
        </Helmet>
      </div>
			<TopBanner />
			<BannerStyles>
				<FloatButton>
					<PlayBtn onClick={() => setShowVideo(true)}>
						<IoMdPlayCircle 
							className="play-icon" 
						/>
					</PlayBtn>
					
				</FloatButton>
				
				<Banner colour="white">
					<h1 className="center black">Do you need a local available Tradesperson?<br /><span>Trade Tribe will find you one.</span></h1>
				</Banner>
			</BannerStyles>
			<UnderBannerStyles>
				<Banner colour="green">
					<Text className="center white">The <strong>FREE, FAST,</strong> smart new way to connect you to your local, vetted, available&nbsp;Tradesperson.<br /><strong>Say goodbye to dinosaur directories</strong>, The Trade Revolution is&nbsp;here!<br /><br />Trade Tribe, building the future</Text>
				</Banner>
			</UnderBannerStyles>

			<TabsContent />

			
			<Player showVideo={showVideo} callback={onCloseCallback} />
		</>
  )
}

export default IndexPage
