import React, { useState } from 'react';
import styled from "styled-components";
import PopUpCloseBar from './PopUpCloseBar';
import ContactForm from './ContactForm';
import Header from './Header';


const BlankContainer = styled.div`
  position: absolute;
	overflow:none;
	z-index:25;
	left:0;
	bottom:0;
	width:100%;
	height:100%;
	background-color:rgba(var(--blackRGB), 1);
	transition: opacity 0.2s;
	&.show{
		pointer-events:all;
		opacity:1;
		display:block;
	}
	&.hide{
		pointer-events:none;
		opacity:0;
		display:none;
	}
`

const PopUpContainer = styled.div`
  position: fixed;
	overflow:auto;
  left:0;
	bottom:0;
	z-index:50;
	width:100%;
	height:100%;
	background-color:rgba(var(--blackRGB), 1);
	transition: opacity 0.2s;
	&.show{
		pointer-events:all;
		opacity:1;
	}
	&.hide{
		pointer-events:none;
		opacity:0;
	}
`

const PopUpWrapper = styled.div`
	padding-top: 0.5rem;
	height:550px;
	overflow:auto;
`
const AddEmailBtn = styled.button`
	background-color:transparent;
	border:none;
	border-radius:0;
	color:var(--white);
	border-bottom:solid 1px var(--white);
	max-width:190px;
	padding-top:2px;
	padding-bottom:2px;
	&:hover, &:focus{
		color:var(--red);
		border-color:var(--red);
	}
	&:focus{
		outline:none;
	}
`
const BtnCnt = styled.div`
	display:flex;
	justify-content:center;
`;

const ContactFormPopUp = () => {
	const [showForm, setShowForm] = useState(false);
	return (
		<>
			<BtnCnt>
				<AddEmailBtn 
					role="button"
					tabIndex="0"
					onClick={() => setShowForm(true)} 
				>Add your email</AddEmailBtn>
			</BtnCnt>
			
			<BlankContainer className={showForm ? 'show' : 'hide'}>

				<PopUpContainer className={showForm ? 'show' : 'hide'}>
					<Header />
					<PopUpCloseBar callback={() => setShowForm(false)} />
					<PopUpWrapper>
						<ContactForm />
					</PopUpWrapper>
				</PopUpContainer>

			</BlankContainer>
			
		</>
	);
};

export default ContactFormPopUp;