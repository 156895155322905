import React from 'react';
import styled from 'styled-components';
import ContactFormPopUp from './ContactFormPopUp';
import SubmitEmail from './SubmitEmail';

const Wrapper = styled.div`
	display:flex;
	background-color:var(--yellow);
`;
const BannerWrapper = styled.div`
	width:90%;
	margin:1rem auto;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	@media (max-width: 900px) {
    flex-direction:column;
  }
`;
const BannerText = styled.div`
	/* width:75%; */
	display:flex;
	justify-content:center; 
	align-items:center;
	margin-right:1rem;
	@media (max-width: 900px) {
    margin-bottom:0.25rem;
		width:100%;
		font-size:1.6rem;
  }
`;
const FormLaunch = styled.div`
	/* width:75%; */
	min-width: 200px;
`;
const TopBanner = () => {
	return (
		<Wrapper>
			<BannerWrapper>
				<BannerText>
					<span>Hold tight, We&#39;re just around the corner! Add your email for the launch -&nbsp;Join&nbsp;the&nbsp;Tribe…</span>
				</BannerText>
				<FormLaunch>
					<ContactFormPopUp />
				</FormLaunch>
				
				{/* <SubmitEmail /> */}
			</BannerWrapper>
		</Wrapper>
	
	);
};

export default TopBanner;