import React from 'react';
import Logo from '../components/Logo';
import styled from "styled-components";
import SubmitEmail from './SubmitEmail';
import { CentralStyle } from '../styles/Layout';


const WrapperStyles = styled.div`
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	padding:3rem;
	.submit-form-wrapper{
		margin:0 0 3rem;
	}
`;

const AppSignUp = () => {

	
	return (
		<>
			<WrapperStyles>
				<div className="submit-form-wrapper">
					<SubmitEmail />
				</div>
				<Logo />
				<h4 className="center">App coming soon</h4>
		 	</WrapperStyles>
		</>
	);
};

export default AppSignUp;