import React from 'react';
import styled from 'styled-components';

const CubeWrapper = styled.div`
	--size:290px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	width:100%;
	min-width:var(--size);
	height:var(--size);
	margin:0 auto;
	/* z-index:15; */
	transition: all 0.2s;
	@media (max-width: 900px) {
		--size:190px;
    width:100%;
		/* margin:1rem; */
  }
	@media (max-width: 500px) {
		--size:223px;
  }
`;

const CubeItem = styled.div`
	background-color:var(--${props => props.colour});
	display:flex;
	position: relative;
	justify-content:center;
	align-items:center;
	padding:1rem;
	font-size:1.5rem;
	text-align:center;
	cursor: pointer;
	border:solid 3px var(--${props => props.border});
	transition: all 0.2s;
	> span{
		position: absolute;
		bottom:5px;
		right:5px;
		text-align:right;
		font-size:2rem;
		font-weight:700;
		opacity:0.5;
	}
	&.selected, &:hover, &:focus, &:active{
		border-color:var(--white);
		background-color:var(--${props => props.hoverColour});
	}
	&.selected > span{
		opacity:1;
	}
	&:focus{
		outline:none;
	}
`;


const Cube = ({ cubeData, onCubeSelected, selectedCube, hoverColour }) => {
	return (
		<CubeWrapper>
			{
				cubeData.map(cd => (
					<CubeItem 
						role="button"
						tabIndex="0"
						className={cd.number === selectedCube ? 'selected' : ''}
						key={cd.number} 
						colour={cd.colour}
						border={cd.border}
						hoverColour={hoverColour}
						onClick={() => onCubeSelected(cd.number)}
					>
						{cd.title}
						<span>{cd.number}</span>
					</CubeItem>
				))
			}
		</CubeWrapper>
	);
};

export default Cube;