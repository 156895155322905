import React, { useState, createRef } from 'react';
import styled from "styled-components";

const FormWrapper = styled.div`
	form{
		display:flex;
		flex-wrap:nowrap;
	}
`;
const InputContainer = styled.div`
	flex:1 0 0%;
	display:flex;
	margin-right:0.5rem;
`;
const EmailInput = styled.input`
  font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	background: transparent!important;
	border: 0;
	border-bottom: solid 1px white;
	color: var(--white);
	width:100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--white)!important;
  }
  :-ms-input-placeholder {
		color: var(--white)!important;
  }
`;
const SubmitButton = styled.button`
	background-color:transparent;
	color: white;
	border: 0;
	padding: 0.6rem;
	border-radius: 0;
	cursor: pointer;
	transition: all 0.2s;
	text-transform:uppercase;
	font-size:1.3rem;
	width:78px;
`;
const SubmitEmail = () => {

	const [isPosting, setIsPosting] = useState(false);
  const [postingError, setPostingError] = useState(false);
  const [postingSuccess, setPostingSuccess] = useState(false);
  const emailEl = createRef();
  const postMail = async () => {
    const email = emailEl.current.value;
		const message = `You have a new contact from the tradetribe website, ${email}`
    setIsPosting(true);

    try {
      const res = await fetch('/.netlify/functions/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message,
        }),
      });

      if (!res.ok) {
        setPostingError(true);
      } else {
        setPostingSuccess(true);
      }
    } catch (e) {
      setPostingError(true);
    } finally {
      setIsPosting(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    postMail();
	};
	

	return (
		<>
      {postingSuccess ? (
        <h4>Email sent. Thank you!</h4>
			) : 
			postingError ? (
				<h4>Something went wrong, please try again later.</h4> ) :
			(
				<FormWrapper>
					<form method="post" onSubmit={handleSubmit}>
							<InputContainer>
								<EmailInput
									type="email"
									aria-label="Add your email"
									name="email"
									id="email"
									placeholder="Add your email"
									ref={emailEl}
									disabled={isPosting ? 'disabled' : undefined}
									required
								/>
							</InputContainer>
							
							<SubmitButton disabled={isPosting ? 'disabled' : undefined}>
								Notify me
							</SubmitButton>
					</form>
				</FormWrapper>
      )}
    </>
	);
};

export default SubmitEmail;