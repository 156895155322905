import React from 'react';
import styled from "styled-components";
import ReactPlayer from 'react-player'
// import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import PopUpCloseBar from './PopUpCloseBar';

const PlayerContainer = styled.div`
  position: absolute;
  left:0;
	top:75px;
	z-index:50;
	width:100%;
	height:586px;
	background-color:rgba(var(--blackRGB), 0.8);
	transition: opacity 0.2s;
	&.show{
		pointer-events:all;
		opacity:1;
	}
	&.hide{
		pointer-events:none;
		opacity:0;
	}
	@media (max-width: 900px) {
		top:95px;
    height:612px;
  }

`
// const PlayerCloseBar = styled.div`
//   background-color:var(--yellow);
// 	display:flex;
// 	height:30px;
// 	width:100%;
// 	align-items:center;
// 	justify-content:flex-end;
// `
// const CloseBtn = styled.button`
//   display:flex;
// 	justify-content:center;
// 	align-items:center;
// 	width:30px;
// 	height:30px;
// 	border:none;
// 	background-color:transparent;
// 	color:var(--black);
// 	font-size:3rem;
// 	line-height:30px;
// 	padding:0;
// 	margin-right:5rem;
// 	&:hover{
// 		color:var(--red);
// 	}
// `
const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
	box-shadow: 0 15px 30px 0 rgba(0,0,0,1.11),0 5px 15px 0 rgba(0,0,0,0.18);
	.react-player{
		position: absolute;
		top: 0;
		left: 0;
	}
`

const Player = ({ showVideo, callback }) => {

	return (
		<PlayerContainer className={showVideo ? 'show' : 'hide'}>
			<PopUpCloseBar callback={callback} />
			{/* <PlayerCloseBar>
				<CloseBtn onClick={() => callback()}>
					<IoMdCloseCircle />
				</CloseBtn>
			</PlayerCloseBar> */}
			<PlayerWrapper>
				<ReactPlayer 
					playing={showVideo}
					className='react-player'
					width="100%"
					height="100%"
					url='https://www.youtube.com/watch?v=yvPJmPOmBmM' 
					volume={0.1}
					controls={true}
				/>
			</PlayerWrapper>
		</PlayerContainer>
	);
};

export default Player;